<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Peserta </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updatePeserta">
              <CRow>
              <CCol sm="6">
                <CInput
                  v-model="nik_pegawai"
                  label="NIK Pegawai"
                  placeholder="Input NIK Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nip_pegawai"
                  label="NIP Pegawai"
                  placeholder="Input NIP Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_pegawai"
                  label="Nama Lengkap"
                  placeholder="Input Nama Lengkap"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tempat_lahir_pegawai"
                  label="Tempat Lahir Pegawai"
                  placeholder="Input Tempat Lahir Pegawai"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_lahir_pegawai"
                  type="date"
                  label="Tanggal Lahir Pegawai"
                  placeholder="Input Tanggal Lahir Pegawai"
                />
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Jenis Kelamin</label>
                  <select v-model="jenis_kelamin_pegawai" class="form-control">
                    <option value="" disabled selected>Pilih Jenis Kelamin</option>
                    <option value="L">Laki-laki</option>
                    <option value="P">Perempuan</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Agama</label>
                  <select v-model="agama_pegawai" class="form-control">
                    <option value="" disabled selected>Pilihan</option>
                    <option value="Islam">Islam</option>
                    <option value="Kristen">Kristen Protestan</option>
                    <option value="Katolik">Kristen Katolik</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Budha">Budha</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Status Perkawinan</label>
                  <select v-model="status_nikah_pegawai" class="form-control">
                    <option value="" disabled selected>Pilihan</option>
                    <option value="1">Belum Kawin</option>
                    <option value="2">Sudah Kawin</option>
                    <option value="3">Janda</option>
                    <option value="4">Duda</option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="3">
                <CInput
                  v-model="telpon_pegawai"
                  type="phone"
                  label="Nomor Telepon Pegawai"
                  placeholder="Input Nomor Telepon Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="jabatan_pegawai"
                  label="Jabatan Pegawai"
                  placeholder="Input Jabatan Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="pangkat_pegawai"
                  label="Pangkat Pegawai"
                  placeholder="Input Pangkat Pegawai"
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model="alamat_pegawai"
                  label="Alamat Pegawai"
                  placeholder="Input Alamat Pegawai"
                />
              </CCol> 
              <CCol sm="3">
                <div class="form-group">
                <label>Pejabat TTD</label>
                  <select v-model="ppk_pejabat_ttd" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Ya</option>
                    <option value="0">Tidak</option>
                  </select>
                </div>
              </CCol>          
            </CRow>      
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/peserta">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nik_pegawai : "",
      nip_pegawai : "",
      nama_pegawai : "",
      alamat_pegawai : "",
      jabatan_pegawai : "",
      tgl_lahir_pegawai : "",
      tempat_lahir_pegawai : "",
      jenis_kelamin_pegawai : "",
      pangkat_pegawai : "",
      agama_pegawai : "",
      telpon_pegawai : "",
      status_nikah_pegawai : "",
      ppk_pejabat_ttd : "",
      selected: [], // Must be an array reference!
      // pesertas: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showPeserta();
  },

  methods: {
    
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      // axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
      //   .then((response) => {
      //         // this.pesertas=response.data;
      //         const datas = response.data;
              
      //         this.pesertas = datas.map(d=>{return d['nama_pegawai']});;
      //         console.log("datas::"+this.pesertas)
      //   });
    },

    updatePeserta: function(event){

      const peserta = { 
                          nik_pegawai: this.nik_pegawai, 
                          nip_pegawai: this.nip_pegawai,
                          nama_pegawai: this.nama_pegawai,
                          alamat_pegawai: this.alamat_pegawai,
                          status_nikah_pegawai: this.status_nikah_pegawai,
                          jabatan_pegawai: this.jabatan_pegawai,
                          pangkat_pegawai: this.pangkat_pegawai,
                          agama_pegawai: this.agama_pegawai,
                          jenis_kelamin_pegawai: this.jenis_kelamin_pegawai,
                          tgl_lahir_pegawai: this.tgl_lahir_pegawai,
                          tempat_lahir_pegawai: this.tempat_lahir_pegawai,
                          telpon_pegawai: this.telpon_pegawai,
                          ppk_pejabat_ttd: this.ppk_pejabat_ttd,
                          id_pegawai: this.$route.params.id_pegawai
                          };

      // alert(JSON.stringify(pekerjaan));
      axios.put(process.env.VUE_APP_BASE_URL+"pegawai/"+this.$route.params.id_pegawai, peserta)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/peserta');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showPeserta: function(event){
    
      const peserta = { 
                          nik_pegawai: this.nik_pegawai, 
                          nip_pegawai: this.nip_pegawai,
                          nama_pegawai: this.nama_pegawai,
                          alamat_pegawai: this.alamat_pegawai,
                          status_nikah_pegawai: this.status_nikah_pegawai,
                          jabatan_pegawai: this.jabatan_pegawai,
                          pangkat_pegawai: this.pangkat_pegawai,
                          agama_pegawai: this.agama_pegawai,
                          jenis_kelamin_pegawai: this.jenis_kelamin_pegawai,
                          tgl_lahir_pegawai: this.tgl_lahir_pegawai,
                          tempat_lahir_pegawai: this.tempat_lahir_pegawai,
                          telpon_pegawai: this.telpon_pegawai,
                          ppk_pejabat_ttd: this.ppk_pejabat_ttd
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pegawai/"+this.$route.params.id_pegawai)
        .then((response) => {            
              this.nip_pegawai=response.data.nip_pegawai;
              this.nik_pegawai=response.data.nik_pegawai;
              this.nama_pegawai=response.data.nama_pegawai;
              this.alamat_pegawai=response.data.alamat_pegawai;
              this.jenis_kelamin_pegawai=response.data.jenis_kelamin_pegawai;
              this.status_nikah_pegawai=response.data.status_nikah_pegawai;
              this.agama_pegawai=response.data.agama_pegawai;
              this.pangkat_pegawai=response.data.pangkat_pegawai;
              this.jabatan_pegawai=response.data.jabatan_pegawai;
              this.telpon_pegawai=response.data.telpon_pegawai;
              this.tempat_lahir_pegawai=response.data.tempat_lahir_pegawai;
              this.tgl_lahir_pegawai=response.data.tgl_lahir_pegawai;
              this.ppk_pejabat_ttd=response.data.ppk_pejabat_ttd;
        })
    }
  }
}
</script>